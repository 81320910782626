<template>
  <div>
    <div class="jumbotron-wrapper color">
      <div class="jumbotron container-lg px-4">
        <div class="animation cover-text mt-5">
          <div class="row gx-5">
            <header class="mb-4">
              <p class="fs-1 fw-bolder mb-1">
                FT-AI Lab第1期｜共建量化生态，为管理人赋能
              </p>
              <div class="fst-italic mb-2">2023年6月28日</div>
              <a
                class="badge bg-secondary text-decoration-none link-light"
                href="#!"
                >新闻</a
              >
              <a
                class="ms-1 badge bg-secondary text-decoration-none link-light"
                href="#!"
                >管理人寻星计划</a
              >
            </header>
          </div>
        </div>
      </div>
    </div>
    <div class="content p-auto">
      <section class="py-5">
        <div class="container-lg px-4">
          <div class="row">
            <div class="col-lg-3">
              <div class="d-flex align-items-center mt-lg-5 mb-4">
                <img
                  class="img-fluid rounded-circle logo_50"
                  src="../../assets/img/logo/logo_50.jpeg"
                  alt="非凸科技"
                />
                <div class="ms-3">
                  <div class="fw-bold">非凸新闻</div>
                  <div class="text-muted">Non-convex News</div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <article class="lh-lg">
                <section class="mb-5">
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/43/01-活动现场.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    近日，非凸科技携手招商证券，共同举办了“FT-AI Lab 管理人寻星计划”系列活动，有幸邀请到磐松资产、汇鸿汇升投资，作为第一期活动专场嘉宾，共同探讨了量化行业趋势、最佳商业实践。FT-AI Lab 将助力量化行业生态建设，汇聚券商、私募、供应商等生态合作伙伴，为金融与科技的共生融合搭建交流平台。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/43/02-招商证券.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    活动伊始，招商证券上海分公司机构业务部负责人鄂天奇发表致辞，介绍了券商业务核心以及未来发展方向，并希望以本次活动为起点，汇聚各方资源，为行业高质量发展赋能。招商证券是国内领先的全牌照综合类券商、A+H股上市券商，通过融合自身强大的投研能力和数据能力，以专业化的系统和服务，提升机构投资者的投资体验及效率。招商证券将坚持“量化服务，科技为先”的理念，持续完善量化交易的最后一公里，为客户提供更加智能化和多样化的交易服务。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/43/03-磐松资产.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    磐松资产秉承“客户为先”的经营理念，专注权益类资产的主动管理，以专业的投资和公平的态度，持续为每一位客户创造价值。活动上，磐松资产交易总监黄大洲、市场总监李新宇共同就“低频量化，大容量权益类资产管理”主题进行了分享，详细介绍了磐松投研及策略。磐松资产拥有一整套成熟的量化模型，由股票回报预测模型、风险预测模型、交易成本预测模型等组成，这些模型的预测值，由磐松自主研发的多账户、多期限投资组合优化器整合后，用来生成磐松旗下所有基金产品的目标投资组合。与此同时，依靠优化器可以做出公平且有远见的交易决策，持续、稳定地将模型预测转化为产品的超额收益，满足不同客户的投资需求。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/43/04-非凸科技.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    为管理人赋能，伴管理人成长，这是非凸科技开展管理人寻星计划的初心。“希望通过系列活动的顺利开展，持续共享各方资源，发掘潜在合作机会，为量化行业生态建设共同发力。也期待与更多小伙伴在更高处相遇、相知，共同成就。”非凸科技商务总监朱家辉说道。针对当前客户真实需求，非凸科技将从“交易执行服务、个性化系统研发、资本引荐”等方面为其全面赋能。非凸科技通过绩效领先的算法生态、先进高效的系统模块，以及雄厚的技术实力，为机构客户提供一站式算法业务解决方案，并通过发挥自身渠道优势，资源互补，扩大机构业务规模和品牌影响力。朱家辉表示，“感谢招商证券对非凸科技的全力支持，让管理人寻星计划有了一个完美开端。未来，期待双方在创新业务方面能够有更深入的合作，携手开拓市场，共同繁荣行业。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/43/05-汇鸿汇升.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    汇鸿汇升的资产配置业务，是以量化模型为支撑，以相对稳定的收益风险为目标的资产配置体系。活动上，汇鸿汇升首席投资官张辉发表了“用资产配置驾驭波动”主题演讲，他表示，“资产配置有最重要的两点，第一要把资产刻画好，这一步决定了我们的战略配置；第二要把这些资产的逻辑跟踪好，这一步直接影响到动态调整的节奏和战术配置。未来，如果整个行业对资产配置的理解能越来越深刻，大型机构的资金管理能以资产配置方式展开运行，尤其当个人资金能通过某种形式聚集到专业的投资机构进行管理，那么就可以真正做到很从容的长期投资。”
                  </p>
                  <p>
                    FT-AI Lab 管理人寻星计划，非凸科技将持续开展，期待更多伙伴加入，共建量化生态，为管理人赋能。
                  </p>
                </section>
              </article>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "News43",
};
</script>

<style></style>
